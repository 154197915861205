import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'
import { Consts } from '@/utils/consts'
import { Api } from '@/utils/api'
import Vue from 'vue'

interface AccountInfo {
  customerId: string
  userId: string
}

@Module({
  dynamic: true,
  store,
  name: 'auth',
  namespaced: true
})
class Auth extends VuexModule {
  private _token: string | null = null
  private _account: AccountInfo | null = null

  public get signed(): boolean {
    return this._token !== null && this._token !== ''
  }

  public get token(): string | null {
    return this._token
  }

  public get account(): AccountInfo | null {
    return this._account
  }

  public get isAdmin(): boolean {
    return this.account === null ? false : this.account.customerId === Consts.ADMIN_CUSTOMER_ID
  }

  public get customerId(): string {
    return this.account === null ? '' : this.account.customerId
  }

  @Mutation
  setToken(token: string | null) {
    this._token = token
    if (token === null || token === '') {
      Vue.$cookies.remove(Consts.SESSION_KEY_TOKEN)
    } else {
      Vue.$cookies.set(Consts.SESSION_KEY_TOKEN, token)
    }
  }

  @Mutation
  setAccount(account: AccountInfo | null) {
    this._account = account
  }

  @Action
  public async login(params: { customerId: string; userId: string; password: string }): Promise<boolean> {
    console.log('login')
    const res = await Api.get(`/account/login`, {
      customerId: params.customerId,
      userId: params.userId,
      password: params.password
    })
    if (res === null || res.token === undefined || res.token === null || res.token === '') {
      return false
    }
    this.setToken(res.token)
    return true
  }

  @Action
  public async getAccountInfo(): Promise<void> {
    this.setAccount(null)
    const token = Vue.$cookies.get(Consts.SESSION_KEY_TOKEN)
    if (token === null || token === '') {
      return
    }
    const res = await Api.get(`/account/payload`)
    if (res === null || res.customerId === undefined || res.customerId === '') {
      return
    }
    this.setAccount(res)
  }

  @Action
  public async hasSession(): Promise<boolean> {
    const token = Vue.$cookies.get(Consts.SESSION_KEY_TOKEN)
    if (token === null || token === '') {
      return false
    }
    const res = await Api.get(`/account/session`)
    if (res === null || res.session !== true) {
      return false
    } else {
      this.setToken(token)
      return true
    }
  }

  @Action
  public logout(): void {
    console.log('logout')
    this.setToken(null)
  }
}
export default getModule(Auth)
