import { Orientation } from './types'

export class Consts {
  static readonly SESSION_KEY_AUTH = 'ainas'
  static readonly API_BASE_URL = {
    development: 'https://s9bqs2tee7.execute-api.ap-northeast-1.amazonaws.com/dev/api',
    // development: 'http://127.0.0.1:8000/api',
    production: 'https://s9bqs2tee7.execute-api.ap-northeast-1.amazonaws.com/prod/api'
  }
  static readonly SESSION_KEY_TOKEN = 'token'
  static readonly ADMIN_CUSTOMER_ID = 'admin'
  static readonly MAX_IMAGE_SIZE_MB = 3
  static readonly MAX_VIDEO_SIZE_MB = 100

  static readonly TEMPLATES: {
    id: string
    name: string
    orientation: Orientation
    src: any
  }[] = [
    { orientation: 'portrait', id: 'p1', name: 'テンプレート1', src: require('@/assets/layout/p1.png') },
    { orientation: 'landscape', id: 'l1', name: 'テンプレート1', src: require('@/assets/layout/l1.png') },
    { orientation: 'landscape', id: 'l2', name: 'テンプレート2', src: require('@/assets/layout/l2.png') },
    { orientation: 'landscape', id: 'l3', name: 'テンプレート3', src: require('@/assets/layout/l3.png') },
    { orientation: 'landscape', id: 'l4', name: 'テンプレート4', src: require('@/assets/layout/l4.png') }
  ]
}
