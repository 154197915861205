import { Mutation, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'

@Module({
  dynamic: true,
  store,
  name: 'app',
  namespaced: true
})
class App extends VuexModule {
  loading = false
  mapTab: Window | null = null
  snackbar = ''
  chatHash = ''
  windowHeight = 0

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }
  @Mutation
  setMapTab(mapTab: Window) {
    this.mapTab = mapTab
  }
  @Mutation
  setSnackbar(snackbar: string) {
    this.snackbar = snackbar
  }
  @Mutation
  setChatHash(hash: string) {
    this.chatHash = hash
  }
  @Mutation
  setWindowHeight(height: number) {
    this.windowHeight = height
  }
}
export default getModule(App)
