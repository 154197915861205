import Vue from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import { Consts } from '@/utils/consts'
import app from '@/store/app'

type ApiResponse = { [key: string]: any }

const apiBaseUrl =
  process.env.NODE_ENV === 'production' ? Consts.API_BASE_URL.production : Consts.API_BASE_URL.development

export class Api {
  static getRequestConfig(): AxiosRequestConfig | any {
    const token = Vue.$cookies.get(Consts.SESSION_KEY_TOKEN)
    return {
      headers: {
        Authorization: token,
        'content-type': 'application/json'
      },
      responseType: 'json'
    }
  }

  static async get(path: string, params: { [key: string]: any } = {}, isLoadingOn = true): Promise<any> {
    if (isLoadingOn === true) {
      app.setLoading(true)
    }
    return axios
      .create(this.getRequestConfig())
      .get(`${apiBaseUrl}${path}`, { params: params })
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        if (app.loading === true) {
          app.setLoading(false)
        }
        console.log(err)
        return null
      })
  }

  static async put(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .put(`${apiBaseUrl}${path}`, params)
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }

  static async post(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .post(`${apiBaseUrl}${path}`, params)
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }

  static async fileUpload(path: string, params: { [key: string]: any }): Promise<any> {
    const encodePath = encodeURI(path)
    const config = this.getRequestConfig()
    config.headers['content-type'] = 'multipart/form-data'
    return axios
      .create(config)
      .post(`${apiBaseUrl}${encodePath}`, params)
      .then((response: ApiResponse) => {
        return response.data
      })
      .catch((err: ApiResponse) => {
        console.log(err)
        return null
      })
  }

  static async delete(path: string, params: { [key: string]: any } = {}): Promise<any> {
    app.setLoading(true)
    return axios
      .create(this.getRequestConfig())
      .delete(`${apiBaseUrl}${path}`, { data: params })
      .then((response: ApiResponse) => {
        app.setLoading(false)
        return response.data
      })
      .catch((err: ApiResponse) => {
        app.setLoading(false)
        console.log(err)
        return null
      })
  }

  static async putPresignedUrl(url: string, file: File): Promise<any> {
    return axios({
      method: 'PUT',
      url: url,
      headers: {
        'Content-Type': file.type
      },
      data: file
    })
      .then((response: ApiResponse) => {
        return response.data
      })
      .catch((err: ApiResponse) => {
        console.log(err)
        return null
      })
  }
}
