import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import auth from '@/store/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    redirect: '/top'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/top',
    name: 'Top',
    component: () => import('../views/Top.vue')
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('../views/Customer.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/machine',
    name: 'Machine',
    component: () => import('../views/Machine.vue')
  },
  {
    path: '/layout/:customerId/:machineId/:layoutId',
    name: 'Layout',
    component: () => import('../views/Layout.vue')
  }
]

const router = new VueRouter({
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login' || to.name === 'ResetPassword' || auth.signed) {
    next()
  } else {
    const hasSession = await auth.hasSession()
    if (hasSession === true) {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})

router.afterEach(async () => {
  if (auth.signed && auth.account === null) {
    await auth.getAccountInfo()
  }
})

export default router
